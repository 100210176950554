<template>
  <div class="main">
    <div class="row infos">
      <div class="col-xl-4 col-lg-12">
        <div class="blc-dados">
          <img src="/img/icon-lines.png" alt="icone porto" />
          <p>List Line-up</p>
        </div>
      </div>
    </div>
    <div class="fundo ">
      <div class="blc-inf-geral">
        <div class="blc-item c-tabela">
          <div class="row">
            <div class="col-md-6 col-12">
              <h2>List <i class="fa fa-spinner fa-spin" v-if="loading"></i></h2>
            </div>
            <div class="col text-right">
              <button class="submit-verde" @click="cleanFiltros()">
                Clear Filters <img src="/img/icon-filter.png" />
              </button>
              <button
                v-if="!$root.isClient"
                class="submit"
                @click="openCreateForm({ id: null })"
              >
                New lineup +
              </button>
            </div>
          </div>

          <div class="row align-items-center mt-3 pesquisa_dashboard">
            <div class="col-lg-6">
              <multiple-select
                multiple
                label="Status"
                placeholder="Select the status"
                :options="Service.status()"
                optionsValue="name"
                optionsKey="name"
                v-model="search.status"
              />
            </div>
            <div class="col-lg-3">
              <multiple-select
                multiple
                label="Charterers"
                placeholder="Select the charterers"
                :options="afretadores"
                optionsValue="name"
                v-model="search.afretador_id"
              />
            </div>
            <div class="col-lg-3">
              <multiple-select
                multiple
                label="Disport"
                placeholder="select the destination port"
                :options="lineupPortos"
                optionsValue="port_name"
                v-model="search.destination_port"
              />
            </div>
            <div class="col-lg-3 mrg-top-30">
              <base-input
                type="date"
                alternative=""
                label="Start period"
                input-classes="form-control-alternative"
                v-model.lazy="search.periodo_inicio"
              />
            </div>
            <div class="col-lg-3 mrg-top-30">
              <base-input
                type="date"
                alternative=""
                label="End period"
                input-classes="form-control-alternative"
                v-model.lazy="search.periodo_fim"
              />
            </div>
            <div class="col-lg-3 mrg-top-30" v-if="!$root.isClient">
              <multiple-select
                multiple
                label="Naabsa vessels"
                placeholder="select naabsa vessels"
                optionsKey="name"
                :options="Service.clientTypes()"
                v-model="search.client_type"
              />
            </div>

            <div class="col-lg-3 mrg-top-30">
              <button
                style="margin-top: 32px"
                class="submit w-100"
                value="Search"
                :disabled="loading"
                @click="listar()"
              >
                <i class="fa fa-spinner fa-spin" v-if="loading"></i> Search
              </button>
            </div>
          </div>

          <div class="row">
            <div class="mrg-top-30 col-12 blc-selected transition_on">
              <span>Ports:</span>

              <a
                :class="[{ ativo: search.porto_id == porto.id }]"
                v-for="porto in portosFiltro"
                @click="setPortoSelecionado(porto)"
                >{{ porto.name }}</a
              >
              <a
                :class="[{ ativo: !search.porto_id.length }]"
                @click="setPortoSelecionado({})"
                >All ports</a
              >
            </div>

            <div class="mrg-top-30 col-12 blc-selected transition_on">
              <span>Comms:</span>

              <a
                v-for="carga in cargas"
                :class="[{ ativo: search.carga_tipo_id == carga.id }]"
                @click="setCarga(carga)"
                >{{ carga.name }}</a
              >

              <a
                v-if="!$root.isClient"
                :class="[{ ativo: !search.carga_tipo_id.length }]"
                @click="setCarga({})"
                >All comms</a
              >
            </div>
          </div>
        </div>
      </div>

      <div v-for="porto in portos" style="margin-top: 50px">
        <div class="row blc-descracao mb-0 pb-1">
          <div class="col-12 col-md-12">
            <div class="blc-tabela scroll">
              <table class="zebrada w-100" style="display: ">
                <thead>
                  <tr>
                    <th colspan="10" class="text-center">Waiting Time(days)</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="chunck in chunckTerminais(porto.terminais)">
                    <tr class="text-white">
                      <td v-for="terminal in chunck">
                        {{ terminal.name }}
                      </td>
                    </tr>
                    <tr>
                      <td v-for="terminal in chunck">
                        {{ terminal.av_waiting_time }}
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="form-row blc-descracao pb-0 blc-descracao-desktop">
          <div class="col-xl-4 col-lg-4 col-md-6 col-12">
            <p>
              BERTHED:
              {{ getVesselsCountByStatus(porto, ["Loading", "Discharging"]) }}
            </p>
          </div>
          <div class="col-xl-4 col-lg-4 col-md-6 col-12">
            <p>
              WAITING AT ANCHORAGE:
              {{ getVesselsCountByStatus(porto, ["Waiting"]) }}
            </p>
          </div>
          <div class="col-xl-4 col-lg-4 col-md-6 col-12">
            <p>
              EXPECTED TO CALL:
              {{ getVesselsCountByStatus(porto, ["Expected"]) }}
            </p>
          </div>
          <div class="col-12">
            <h4 class="mt-1">{{ porto.name }}</h4>
          </div>
        </div>
        <!--mobile-->
        
          <div class="form-row blc-descracao pb-0 descracao_mobile " style="padding:0 10px;">
            <div class="col-12">
            <h4 class="mt-1">{{ porto.name }}</h4>
          </div>
          <div style="margin: auto;display: table;">
            <div style="display:flex;padding: 0 5px;">
              <div class="">
                <div class="result_top">BERTHED:</div>
                <p class="result"> 
                  {{ getVesselsCountByStatus(porto, ["Loading", "Discharging"]) }}
                </p>
              </div>
              <div class="">
                <div class="result_top">WAITING:</div>
                <p class="result">
                  {{ getVesselsCountByStatus(porto, ["Waiting"]) }}
                </p>
              </div>
              <div class="">
                <div class="result_top">EXPECTED:</div>
                <p class="result">  
                  {{ getVesselsCountByStatus(porto, ["Expected"]) }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <!--mobile-->
        <template v-for="terminal in porto.terminais">
          <div>
            <table_lineup
              @openModal="openCreateForm"
              @newLineup="
                openCreateForm({
                  id: null,
                  porto_id: terminal.porto_id,
                  terminal_id: terminal.id,
                })
              "
              @exportLineups="exportLineups(terminal.id)"
              @refresh="listar(true)"
              :carga="cargaSelecionada"
              :cargas="cargas.reduce((a, b) => a.concat(b.cargas), [])"
              :carga_tipo_id="search.carga_tipo_id[0]"
              :navios="navios"
              :afretadores="afretadores"
              :lineupPortos="lineupPortos"
              :agencias="agencias"
              :terminal="terminal"
              :userPortosIds="userPortosIds"
              :lineups="
                list.filter((lineup) => lineup.terminal_id == terminal.id)
              "
            ></table_lineup>
          </div>
          <h5 v-if="terminal.remark" class="p-1 bg-warning my-0">
            {{ terminal.remark }}
          </h5>
        </template>
      </div>

      <div class="row blc-descracao" v-if="!portos.length && !loading">
        <div class="col-12">
          <h4>
            There is no port linked with this comm<i
              class="fa fa-spinner fa-spin"
              v-if="loading"
            ></i>
          </h4>
        </div>
      </div>
    </div>
    <excluir-modal
      :show="$root.modal.excluir.isOpen"
      :mensagem="$root.modal.excluir.mensagem"
      :model="$root.modal.excluir.model"
      :service="$root.modal.excluir.service"
      @close="handleExcluir"
    ></excluir-modal>
    <modal
      :show="modal.form.show"
      @close="
        () => {
          modal.form.show = false;
          listar(true);
        }
      "
      modalClasses="modal-xl"
    >
      <template v-slot:header>
        <span style="color: transparent">Lineup</span>
      </template>
      <template>
        <lineup-form
          v-if="modal.form.show"
          :id="modal.form.id"
          :isModal="1"
          :premodel="modal.form.premodel"
          :withParams="true"
          :params="{
            navios: navios,
            portos: portosFiltro,
            agencias: agencias,
            cargasTipos: cargas,
            lineupPortos: lineupPortos,
            afretadores: afretadores,
          }"
        />
      </template>
    </modal>
  </div>
</template>

<script>
import Vue from "vue";
import Multiselect from "vue-multiselect";
import Service from "@/services/Lineups";
import PortosService from "@/services/Portos";
import CargasTiposService from "@/services/CargasTipos";
import TableLineup from "./Table.vue";
import FormLineup from "./Form.vue";
export default {
  components: {
    table_lineup: TableLineup,
    "lineup-form": FormLineup,
    Multiselect,
  },
  data() {
    return {
      Service,
      cargaSelecionada: {},
      portoSelecionado: {},
      cargas: [],
      navios: [],
      afretadores: [],
      terminais: [],
      agencias: [],
      bercos: [],
      terminal: {},
      lineupPortos: [],
      trabalhadores: [],
      loading: false,
      pagination: {
        page: 1,
        last_page: 1,
        per_page: 20,
        total: 1,
      },
      modal: {
        form: {
          show: false,
          id: null,
          premodel: {},
        },
      },
      list: [],
      portos: [],
      portosFiltro: [],
      search: {
        carga_tipo_id: [],
        porto_id: "",
        status: [
          "Loading",
          "Discharging",
          "Waiting",
          "Expected",
          "POB hh:mm lt",
        ],
        recebedores: [],
        embarcadores: [],
        agencia_id: [],
        navio_id: [],
        afretador_id: [],
        periodo_inicio: null,
        periodo_fim: null,
        destination_port: null,
        client_type: [],
      },
      defaultSearch: {},
      orderBy: {
        campo: "name",
        ordem: "asc",
      },
      userPortosIds: [],
    };
  },
  created() {
    // this.search.periodo_inicio = moment().startOf("month").format("YYYY-MM-DD");
    // this.search.periodo_fim = moment().endOf("month").format("YYYY-MM-DD");
    this.defaultSearch = { ...this.search };
    PortosService.get({ with: "terminais.bercos" }).then((response) => {
      this.portosFiltro = response.data;
      this.portoSelecionado = this.portosFiltro[0];
      this.search.porto_id = [this.portoSelecionado.id];
      this.listarCargasTipos();
    });

    Service.parametros({
      navios: true,
      agencias: true,
      lineups_portos: true,
      afretadores: true,
      trabalhadores: true,
      portos: true,
    }).then((response) => {
      this.navios = response.data.navios;
      this.lineupPortos = response.data.lineups_portos;
      this.agencias = response.data.agencias;
      this.afretadores = response.data.afretadores;
      this.trabalhadores = response.data.trabalhadores;
      this.userPortosIds = response.data.portos.map((p) => p.id);
    });
  },
  watch: {
    // orderBy: {
    //   deep: true,
    //   handler() {
    //     this.pagination.page = 1;
    //     this.listar();
    //   },
    // },
    search: {
      deep: true,
      handler() {
        this.listar();
      },
    },
  },
  methods: {
    openCreateForm(lineup) {
      this.modal.form.show = true;
      this.modal.form.id = lineup.id;
      this.modal.form.premodel = lineup;
    },
    handleExcluir(updated) {
      this.$root.modal.excluir.isOpen = false;
      if (updated) this.listar();
    },
    download(aviso) {
      if (aviso) {
        if (
          !confirm("Realmente deseja baixar com as agências?", "Sim", "Não")
        ) {
          return false;
        }
      }
      this.$notify({
        type: "info",
        message: "Please wait for the download to start",
        horizontalAlign: "center",
      });

      Service.download({
        ...this.search,

        agencia: aviso ? 1 : 0,
      }).then((response) => {
        const blob = new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,",
        });
        const objectUrl = window.URL.createObjectURL(blob);

        var a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        a.href = objectUrl;

        a.download = "lineups.xlsx";
        a.click();
        window.URL.revokeObjectURL(objectUrl);
      });
    },

    setCarga(carga) {
      if (carga) {
        this.cargaSelecionada = carga;
        this.search.carga_tipo_id = carga?.id ? [carga.id] : [];
      } else {
        this.cargaSelecionada = {};
      }
      // this.listar();
    },
    setPortoSelecionado(porto) {
      if (porto) {
        this.portoSelecionado = porto;
        this.search.porto_id = porto?.id ? [porto.id] : [];
      }
      // this.listar();
    },
    cargaAtualizada(carga, atualizar) {
      this.loading = true;
      CargasTiposService.atualizar(carga, atualizar).then((response) => {
        carga.atualizado_data = response.data.atualizado_data;
        carga.atualizador = response.data.atualizador;
        this.$notify({
          type: "success",

          message: "Updated successfully!",
          horizontalAlign: "center",
        });
        this.loading = false;
      });
    },

    portoAtualizado(porto, carga, atualizar) {
      this.loading = true;
      PortosService.atualizar(porto, carga, atualizar).then((response) => {
        porto.carga_atualizada = response.data;
        this.$notify({
          type: "success",
          message: "Updated successfully!",
          horizontalAlign: "center",
        });
        this.loading = false;
      });
    },
    listarCargasTipos() {
      this.loading = true;
      this.cargas = [];
      CargasTiposService.get({
        with: ["cargas", "atualizador"],
        ...this.search,
      }).then((response) => {
        this.cargas = response.data;
        this.setCarga(this.cargas[0]);
        this.loading = false;
        // this.listar();
      });
    },
    listar(clear) {
      this.loading = true;

      this.listarPortos();
      // this.list = [];

      Service.get({
        ...this.search,
        with: [
          "carga",
          "cargaTipo",
          "terminal",
          "berco",
          "cargas",
          "afretadores",
          "cargas_afretadores",
          // "recebedores",
          "afretador",
          // "embarcadores",
          "agencia",
          "navio",
          // "importPorto",
          // "exportPorto",
          "destination_port",
        ],
      }).then((response) => {
        this.list = response.data;
        this.list.map((list) => Vue.set(list, "isInEdit", false));
        this.loading = false;
      });
    },
    cleanFiltros() {
      this.search = { ...this.defaultSearch };
      this.search.carga_tipo_id = this.cargaSelecionada.id
        ? [this.cargaSelecionada.id]
        : [];
      this.listar();
    },
    listarPortos() {
      this.loading = true;
      // this.portos = [];
      PortosService.get({
        id: this.search.porto_id,
        campo: "ordem",
        ordem: "asc",
        carga_tipo_id: this.search.carga_tipo_id,
        with: "terminais.bercos",

        // ...this.search
      }).then((response) => {
        this.portos = response.data;
        this.loading = false;
      });
    },
    getVesselsCountByStatus(porto, status = []) {
      const list = this.list.filter((l) => l.porto_id == porto.id);
      return list.filter((l) => status.includes(l.status)).length;
    },
    chunckTerminais(terminais = [], size = 10) {
      const chunck = [];
      for (let i = 0; i < terminais.length; i += size) {
        chunck.push(terminais.slice(i, i + size));
      }
      return chunck;
    },
    exportLineups(terminalId) {
      Service.download({ terminal_id: terminalId, ...this.search }).then(
        (response) => {
          const blob = new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,",
          });
          const objectUrl = window.URL.createObjectURL(blob);
          var a = document.createElement("a");
          document.body.appendChild(a);
          a.style = "display: none";
          a.href = objectUrl;
          a.download = "lineups.xlsx";
          a.click();
          window.URL.revokeObjectURL(objectUrl);
        }
      );
    },
  },
};
</script>
<style scoped>
.cursor-pointer {
  cursor: pointer;
}
.form-group {
  margin-bottom: 0.5rem;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #525f7f;
  background-color: #f8f9fe;
  border-color: #123e76 #123e76 #fff;
}
.nav-tabs {
  border-bottom: 1px solid #123e76;
}
#ports-tabs li.nav-item a.nav-link,
#comms-tabs li.nav-item a.nav-link {
  cursor: pointer;
}
.status-table tr td {
  border: solid 1px black;
}
#terminal-fields div p {
  height: 80px;
}
/*****************************************
                  NOVOS
******************************************/

.descracao_mobile{
  display: none;
}

@media (max-width: 991px){
  .descracao_mobile{
    display: block!important;
  }
  .blc-descracao-desktop{
    display: none!important;
  }
  .blc-descracao{
    padding: 0;
  }
  .result_top{
    background: #414141;
    text-align: center;
    text-transform: uppercase;
    color: #fff;
    width: 95px;
    font-family: var(--font-oswald);
    font-weight: var(--font-extralight);
    font-size: 14px;
    height: 50px;
    border-radius: 5px;
    display: -webkit-box;
    display: -ms-flexbox;
    justify-content: center;
    margin-top: 7px;
    margin-right: 5px;
    align-items: center;
  }
  .result{
    background: #ebebeb;
    text-align: center;
    text-transform: uppercase;
    color: #414141;
    width: 95px;
    font-family: var(--font-oswald);
    font-weight: var(--font-extralight);
    font-size: 14px;
    height: 50px;
    border-radius: 5px;
    display: -webkit-box;
    display: -ms-flexbox;
    justify-content: center;
    margin-top: 7px;
  }
}

</style>
