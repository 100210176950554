var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    { 'input-group': _vm.hasIcon },
    // {'has-danger': errors.length},
    { 'form-group': _vm.label },
    { focused: _vm.focused },
    { 'has-label': _vm.label || _vm.$slots.label } ]},[_vm._t("label",[(_vm.label)?_c('label',{staticClass:"form-control-label w-100",class:_vm.labelClasses},[_vm._v(" "+_vm._s(_vm.label)+" "),(_vm.required)?_c('span',[_vm._v("*")]):_vm._e(),(_vm.locked)?_c('span',{staticClass:"float-right"},[_c('i',{staticClass:"fa",class:_vm.lock ? 'fa-lock' : 'fa-lock-open',attrs:{"title":"Lock year?"},on:{"click":function($event){_vm.lock = _vm.lock ? false : true}}})]):_vm._e()]):_vm._e()]),(_vm.addonLeftIcon || _vm.$slots.addonLeft)?_c('div',{staticClass:"input-group-prepend"},[_c('span',{staticClass:"input-group-text"},[_vm._t("addonLeft",[_c('i',{class:_vm.addonLeftIcon})])],2)]):_vm._e(),_vm._t("default",[(((_vm.$attrs).type)==='checkbox')?_c('input',_vm._b({directives:[{name:"mask",rawName:"v-mask",value:(!_vm.locked || (_vm.locked && !_vm.lock) ? '##/##/####' : '##/##/' + _vm.year),expression:"!locked || (locked && !lock) ? '##/##/####' : '##/##/' + year"},{name:"model",rawName:"v-model",value:(_vm.model),expression:"model"}],staticClass:"form-control",class:[
        { 'is-valid': _vm.valid === true },
        { 'is-invalid': _vm.valid === false },
        _vm.inputClasses ],attrs:{"aria-describedby":"addon-right addon-left","type":"checkbox"},domProps:{"checked":Array.isArray(_vm.model)?_vm._i(_vm.model,null)>-1:(_vm.model)},on:{"change":[function($event){var $$a=_vm.model,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.model=$$a.concat([$$v]))}else{$$i>-1&&(_vm.model=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.model=$$c}},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"lazy",undefined,$event.key,undefined)){ return null; }return _vm.updateValue($event)}],"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"tab",9,$event.key,"Tab")){ return null; }return _vm.handleTab($event)}}},'input',_vm.$attrs,false)):(((_vm.$attrs).type)==='radio')?_c('input',_vm._b({directives:[{name:"mask",rawName:"v-mask",value:(!_vm.locked || (_vm.locked && !_vm.lock) ? '##/##/####' : '##/##/' + _vm.year),expression:"!locked || (locked && !lock) ? '##/##/####' : '##/##/' + year"},{name:"model",rawName:"v-model",value:(_vm.model),expression:"model"}],staticClass:"form-control",class:[
        { 'is-valid': _vm.valid === true },
        { 'is-invalid': _vm.valid === false },
        _vm.inputClasses ],attrs:{"aria-describedby":"addon-right addon-left","type":"radio"},domProps:{"checked":_vm._q(_vm.model,null)},on:{"change":[function($event){_vm.model=null},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"lazy",undefined,$event.key,undefined)){ return null; }return _vm.updateValue($event)}],"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"tab",9,$event.key,"Tab")){ return null; }return _vm.handleTab($event)}}},'input',_vm.$attrs,false)):_c('input',_vm._b({directives:[{name:"mask",rawName:"v-mask",value:(!_vm.locked || (_vm.locked && !_vm.lock) ? '##/##/####' : '##/##/' + _vm.year),expression:"!locked || (locked && !lock) ? '##/##/####' : '##/##/' + year"},{name:"model",rawName:"v-model",value:(_vm.model),expression:"model"}],staticClass:"form-control",class:[
        { 'is-valid': _vm.valid === true },
        { 'is-invalid': _vm.valid === false },
        _vm.inputClasses ],attrs:{"aria-describedby":"addon-right addon-left","type":(_vm.$attrs).type},domProps:{"value":(_vm.model)},on:{"change":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"lazy",undefined,$event.key,undefined)){ return null; }return _vm.updateValue($event)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"tab",9,$event.key,"Tab")){ return null; }return _vm.handleTab($event)},"input":function($event){if($event.target.composing){ return; }_vm.model=$event.target.value}}},'input',_vm.$attrs,false))],null,_vm.slotData),(_vm.addonRightIcon || _vm.$slots.addonRight)?_c('div',{staticClass:"input-group-append"},[_c('span',{staticClass:"input-group-text"},[_vm._t("addonRight",[_c('i',{class:_vm.addonRightIcon})])],2)]):_vm._e(),_vm._t("infoBlock"),_vm._t("helpBlock",[_vm._l((_vm.errors),function(erro){return (_vm.errors)?_c('div',{staticClass:"text-danger invalid-feedback",class:{ 'mt-2': _vm.hasIcon },staticStyle:{"display":"block"}},[_vm._v(" "+_vm._s(erro)+" ")]):_vm._e()}),_vm._l((_vm.componentErrors),function(erro){return (_vm.componentErrors)?_c('div',{staticClass:"text-danger invalid-feedback",class:{ 'mt-2': _vm.hasIcon },staticStyle:{"display":"block"}},[_vm._v(" "+_vm._s(erro)+" ")]):_vm._e()})])],2)}
var staticRenderFns = []

export { render, staticRenderFns }